/**
 * Configuration for the domain of the API
 */
let domain;

if (process.env.ENVIRONMENT === 'production') {
    // In production, use the environment variable
    domain = process.env.APIDOMAIN;
} else {
    // In development, load from the local file
    const localEnv = require('../../ENV');
    domain = localEnv.domain;
}

/** 
 * Configuration for index page content in 'Steps' preview on hover
*/
const ignore = new Set(['Project pitch','Registrations', 'Budget request form']);
const detail = new Set(['Registrations', 'Budget', 'Status report', 'Press hits', 'Spent', 'Pre-survey']);
const substitute = new Map([

]);

// Used for overriding the required viewing, used only for development
const override = true;

/**
 * Configuration for the mapping of month from Date() to its string equivalent
 */
const month = new Map([
    [0, 'January'],
    [1, 'February'],
    [2, 'March'],
    [3, 'April'],
    [4, 'May'],
    [5, 'June'],
    [6, 'July'],
    [7, 'August'],
    [8, 'September'],
    [9, 'October'],
    [10, 'November'],
    [11, 'December'],
]);

/**
 * Configuration for the dropdown menu of the help request form
 */
const optionsHelp = new Map([
    ['1', ['Identifying Action Items', 'Developing Team Structure', 'Choosing a Topic']],
    ['2', ['How to Conduct Research', 'Conducting Primary Research', 'Conducting Secondary Research',
        'Conducting Topical Research', 'Conducting Target Audience Research', 'Subject Matter Expertise',
        'Developing a Name or Slogan', 'Developing Creative Elements', 'Developing Strategies and Tactics']],
    ['3', ['Identifying What Should be Measured', 'How to Set Benchmarks', 'Sources and Methods for Data Collection and Analysis',
        'Executing Strategies and Tactics', 'How to Test and Revise Strategies and Tactics', 'Outreach',
        'Social Media Use', 'Holding Events', 'Product or Tool Testing', 'Data Collection']],
    ['4', ['Post-research', 'Measuring Effectiveness', 'Developing and Review of a Final Report Draft',
        'Project Wrap-up Reporting on the Program Portal', 'Final Portfolio Items']],
    ['budget', ['Completing Budget Request Form', 'Allowable Expenses', 'Recording Expenses and Receipts', 'Project Manager Approval']]
]);

/** Budget Request form configuration 
 * 
*/
const showOptions = true;

/**
 * Program for this instance of the client portal
*/
const Program = 'Building Allies of Coexistence';
const Semester = 'Spring';
const Year = '2025';

/**
 * Map that helps facilitate the API endpoints used for tracking items on specific events 
 */
const route = new Map([
    ['Curriculum', `v1.1/student/step2/${encodeURIComponent('Curriculum')}`],
    ['Project pitch example', `v1.1/student/step2/${encodeURIComponent('Examples')}`],
    ['Final report example', `v1.1/student/step4/${encodeURIComponent('Examples')}`],
    ['Student login', 'v1.1/student/login'],
    ['Instructor login', 'v1.1/instructor/login'],
    ['Registrations', `v1.1/student/step1/${encodeURIComponent('Registrations')}`],
    ['Kickoff material', `v1.1/student/step1/${encodeURIComponent('Kickoff material')}`],
    ['Project brief', `v1.1/student/step1/${encodeURIComponent('Project brief')}`],
    ['Pre-survey', `v1.1/student/step1/${encodeURIComponent('Pre-survey')}`],
    ['Rules', `v1.1/student/step1/${encodeURIComponent('Rules')}`],
    ['Scorecard', `v1.1/student/step1/${encodeURIComponent('Scorecard')}`],
    ['Research material', `v1.1/student/step2/${encodeURIComponent('Research material')}`],
    ['Status report', `v1.1/student/step2/${encodeURIComponent('Status report')}`],
    ['Project pitch', `v1.1/student/step2/${encodeURIComponent('Project pitch')}`],
    ['Project check-in', `v1.1/student/step2/${encodeURIComponent('Project check-in')}`],
    ['Social media handles', `v1.1/student/step3/${encodeURIComponent('Social media handles')}`],
    ['Creative materials', `v1.1/student/step3/${encodeURIComponent('Creative materials')}`],
    ['Photos and videos', `v1.1/student/step3/${encodeURIComponent('Photos and videos')}`],
    ['Press hits', `v1.1/student/step3/${encodeURIComponent('Press hits')}`],
    ['Metrics', `v1.1/student/step4/${encodeURIComponent('Metrics')}`],
    ['Final report', `v1.1/student/step4/${encodeURIComponent('Final report')}`],
    ['Post-program survey', `v1.1/student/step4/${encodeURIComponent('Post-program survey')}`],
    ['Budget request form', `v1.1/student/budgetManagement/${encodeURIComponent('Budget request form')}`],
    ['Refund', `v1.1/student/refundReceipt`],
    ['Receipt', 'v1.1/student/receipt'],
    ['Guide to step 2', 'v1.1/student/step2/Guide'],
    ['Guide to step 3', 'v1.1/student/step3/Guide'],
    ['Guide to step 4', 'v1.1/student/step4/Guide'],
    ['Guide to budget management', 'v1.1/student/budgetManagement/Guide'],
]);

/**
 * URL to POST logs to Podio
 */
const loginLogger = 'https://workflow-automation.podio.com/catch/k46tgql0kbta9s4';
const bootstrapLogger = 'https://workflow-automation.podio.com/catch/0ajqnu2kjbjdxrn';

/**
 * Map that helps facilitate the incrementing of tracked items in memory, to update what the user sees
 */
const step = new Map([
    ['Registrations', 'step1'],
    ['Kickoff material', 'step1'],
    ['Project brief', 'step1'],
    ['Pre-survey', 'step1'],
    ['Rules', 'step1'],
    ['Scorecard', 'step1'],
    ['Project pitch example', 'step2'],
    ['Curriculum', 'step2'],
    ['Research material', 'step2'],
    ['Status report', 'step2'],
    ['Project pitch', 'step2'],
    ['Project check-in', 'step2'],
    ['Guide to step 2', 'step2'],
    ['Social media handles', 'step3'],
    ['Creative materials', 'step3'],
    ['Photos and videos', 'step3'],
    ['Press hits', 'step3'],
    ['Guide to step 3', 'step3'],
    ['Metrics', 'step4'],
    ['Final report', 'step4'],
    ['Final report example', 'step4'],
    ['Post-program survey', 'step4'],
    ['Guide to step 4', 'step4'],
    ['Budget request form', 'budgetManagement'],
    ['Receipt', 'budgetManagement'],
    ['Refund', 'budgetManagement'],
    ['Spent', 'budgetManagement'],
    ['Guide to budget management', 'budgetManagement'],
]);

const buttonFontFamily = "'Verdana', sans-serif";
const buttonTitleWidth = 80; // percent

/**
 * iconPreference determines whether to use PNGs or colors as menu button backgrounds. Set
 * to true to use PNGs, or set to false to use colors. If set to true but no PNGs are
 * available, colors are used.
*/
const iconPreference = false;

/**
 * buttonIcons is an array of PNGs that may be cycled through to give menu buttons
 * background images.
 */
const buttonIcons = [
    // TODO: add PNGs to the repo, statically import them, and list them here.
];

const buttonColors = [
    // Color namer: https://colornamer.robertcooper.me/
    '#086fc0', // Blue Diamond
    '#223b48', // Tarawera
    '#c65a58', // Dark Salmon
    '#78a55c', // Grassy Meadow
    '#e56b38', // Celosia Orange
];

/**
 * twoPerRowStr defines the font size percentage of button titles based on how long the
 * titles are for rows with two buttons. The keys are title lengths and the values are
 * font size percentages.
 */
const twoPerRowStr = new Map([
    [10, 180],
    [20, 160],
    [30, 160],
    [40, 150],
    [50, 150],
    [60, 140],
    [70, 140],
    [70, 130],
]);

/**
 * twoPerRowSubstr defines the font size percentage of a button title based on the length
 * of the longest word in the title for rows with two buttons. The keys are longest word
 * lengths and the values are font size percentages.
 */
const twoPerRowSubstr = new Map([
    [10, 180],
    [12, 160],
    [14, 140],
    [16, 120],
    [18, 110],
    [20, 100],
    [22, 95],
    [24, 90],
    [26, 85],
]);

/**
 * threePerRow defines the font size percentage of button titles based on how long the
 * titles are for rows with three buttons. The keys are title lengths and the values are
 * font size percentages.
 */
const threePerRowStr = new Map([
    [10, 150],
    [20, 130],
    [30, 130],
    [40, 120],
    [50, 120],
    [60, 110],
    [70, 110],
    [80, 100],
]);

/**
 * threePerRowSubstr defines the font size percentage of a button title based on the
 * length of the longest word in the title for rows with three buttons. The keys are
 * longest word lengths and the values are font size percentages.
 */
const threePerRowSubstr = new Map([
    [14, 120],
    [16, 100],
    [18, 80],
    [20, 70],
    [22, 65],
    [24, 60],
    [26, 55],
]);

/**
 * fourPerRow defines the font size percentage of button titles based on how long the
 * titles are for rows with four buttons. The keys are title lengths and the values are
 * font size percentages.
 */
const fourPerRowStr = new Map([
    [10, 110],
    [20, 80],
    [30, 80],
    [40, 70],
    [50, 70],
    [60, 60],
    [70, 60],
    [80, 50],
]);

/**
 * fourPerRowSubstr defines the font size percentage of a button title based on the length
 * of the longest word in the title for rows with four buttons. The keys are longest word
 * lengths and the values are font size percentages.
 */
const fourPerRowSubstr = new Map([
    [14, 140],
    [16, 75],
    [18, 65],
    [20, 60],
    [22, 55],
    [24, 50],
    [26, 45],
]);

export {
    detail,
    domain,
    examplesFinalReport,
    ignore,
    loginLogger,
    bootstrapLogger,
    month,
    optionsHelp,
    Program,
    Semester,
    Year,
    route,
    showOptions,
    step,
    substitute,
    override,
    buttonFontFamily,
    buttonIcons,
    buttonTitleWidth,
    iconPreference,
    buttonColors,
    twoPerRowStr,
    twoPerRowSubstr,
    threePerRowStr,
    threePerRowSubstr,
    fourPerRowStr,
    fourPerRowSubstr,
}
